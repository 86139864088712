<div class="page-wrapper">
<!-- Preloader -->
<div class="preloader"></div>

<!-- Main Header-->
<header class="main-header header-style-one">

  <!-- Header Top -->
  <div class="header-top">
    <div class="auto-container">
      <div class="clearfix">
        <!-- Top Left -->
        <div class="top-left">
          <!-- Info List -->
          <ul class="info-list">
            <li><a href="mailto:info@giventa.com"><span class="icon flaticon-email"></span> info@giventa.com</a></li>
            <li><a href="tel:+1 312-343-0073"><span class="icon flaticon-telephone"></span> +1 312-343-0073</a></li>
          </ul>
        </div>

        <!-- Top Right -->
        <div class="top-right pull-right">
          <!-- Social Box -->
          <ul class="social-box">
            <li><a href="#" class="fa fa-facebook-f"></a></li>
            <li><a href="#" class="fa fa-twitter"></a></li>
            <li><a href="#" class="fa fa-dribbble"></a></li>
            <li><a href="#" class="fa fa-google"></a></li>
          </ul>
        </div>

      </div>
    </div>
  </div>

  <!--Header-Upper-->
  <!--<div class="header-upper">
    <div class="auto-container clearfix">-->

     <!-- <div class="pull-left logo-box">
        <div class="logo"><a [routerLink]="['/home']"><img src="../assets/images/giventalogo_180x60_menu_bar.png" alt="logo" title=""></a></div>
      </div>-->

     <!-- <div class="nav-outer clearfix">-->
        <!--Mobile Navigation Toggler-->
       <!-- <div class="mobile-nav-toggler"><span class="icon flaticon-menu"></span></div>-->
        <!-- Main Menu -->

      <nav class="navbar navbar-expand-sm bg-light navbar-light">
        <div class="container-fluid">
          <div class="navbar-header">
            <a class="navbar-brand" [routerLink]="['/home']"><img src="../assets/images/giventalogo_180x60_menu_bar.png" alt="logo" title=""></a>
          </div>
          <ul class="navbar-nav">
            <li class="nav-item active ">  <a class="nav-link navigation-menu" [routerLink]="['/home']">Home</a>  </li>
            <li class="nav-item ">  <a class="nav-link navigation-menu" [routerLink]="['/about-us']">About</a>  </li>
            <li class="nav-item ">  <a class="nav-link navigation-menu" [routerLink]="['/services']">Services</a>  </li>
            <li class="nav-item ">  <a class="nav-link navigation-menu" [routerLink]="['/contact-us']">Contact us</a>  </li>
          </ul>
        </div>
      </nav>

        <!--<nav class="main-menu ">
          <div class="navbar-header">
            &lt;!&ndash; Toggle Button &ndash;&gt;
           &lt;!&ndash; <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>&ndash;&gt;
          </div>

          <div class="navbar-inverse clearfix" id="navbarSupportedContent">
            <ul class="navigation clearfix">
              <li class="current dropdown"><a [routerLink]="['/home']">Home</a>
               &lt;!&ndash; <ul>
                  <li class="dropdown"><a href="#">Home Pages</a>
                    <ul>
                      <li><a href="index.html">Home Page 01</a></li>
                      <li><a href="index-2.html">Home Page 02</a></li>
                      <li><a href="index-3.html">Home Page 03</a></li>
                    </ul>
                  </li>
                  <li class="dropdown"><a href="#">Header styles</a>
                    <ul>
                      <li><a href="index.html">Header Style 01</a></li>
                      <li><a href="index-2.html">Header Style 02</a></li>
                      <li><a href="index-3.html">Header Style 03</a></li>
                      <li><a href="index-4.html">Header Style 04</a></li>
                      <li><a href="index-5.html">Header Style 05</a></li>
                      <li><a href="index-6.html">Header Style 06</a></li>
                    </ul>
                  </li>
                </ul>&ndash;&gt;
              </li>
              <li > <a [routerLink]="['/about-us']">About</a>
              &lt;!&ndash;  <ul>
                  <li><a href="about.html">About Us</a></li>
                  <li><a href="faq.html">Faq</a></li>
                  <li><a href="price.html">Price</a></li>
                  <li><a href="team.html">Team</a></li>
                  <li><a href="testimonial.html">Testimonial</a></li>
                  <li><a href="comming-soon.html">Comming Soon</a></li>
                </ul>&ndash;&gt;
              </li>
              <li > <a [routerLink]="['/services']">Services</a>
                &lt;!&ndash;<ul>
                  <li><a href="services.html">Services</a></li>
                  <li><a href="services-detail.html">Services Detail</a></li>
                </ul>&ndash;&gt;
              </li>
             &lt;!&ndash; <li class="dropdown"><a href="#">Projects</a>
                <ul>
                  <li><a href="projects.html">Projects</a></li>
                  <li><a href="projects-detail.html">Projects Detail</a></li>
                </ul>
              </li>&ndash;&gt;
             &lt;!&ndash; <li class="dropdown"><a href="#">Shop</a>
                <ul>
                  <li><a href="shop.html">Our Products</a></li>
                  <li><a href="shop-single.html">Product Single</a></li>
                  <li><a href="shopping-cart.html">Shopping Cart</a></li>
                  <li><a href="checkout.html">Checkout</a></li>
                  <li><a href="account.html">Account</a></li>
                </ul>
              </li>&ndash;&gt;
              &lt;!&ndash;<li class="dropdown"><a href="#">Blog</a>
                <ul>
                  <li><a href="blog.html">Our Blog</a></li>
                  <li><a href="blog-classic.html">Blog Classic</a></li>
                  <li><a href="news-detail.html">Blog Single</a></li>
                  <li><a href="not-found.html">Not Found</a></li>
                </ul>
              </li>&ndash;&gt;
              &lt;!&ndash;<li><a href="contact.html">Contact us</a></li>&ndash;&gt;
              <li>  <a [routerLink]="['/contact-us']">Contact us</a>  </li>

            </ul>
          </div>
        </nav>-->

        <!-- Main Menu End-->
     <!--   <div class="outer-box clearfix">-->

          <!-- Cart Box -->
          <!--<div class="cart-box">
            <div class="dropdown">
              <button class="cart-box-btn dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="flaticon-shopping-bag-1"></span><span class="total-cart">2</span></button>
              <div class="dropdown-menu pull-right cart-panel" aria-labelledby="dropdownMenu1">

                <div class="cart-product">
                  <div class="inner">
                    <div class="cross-icon"><span class="icon fa fa-remove"></span></div>
                    <div class="image"><img src="images/resource/post-thumb-1.jpg" alt="" /></div>
                    <h3><a href="shop-single.html">Flying Ninja</a></h3>
                    <div class="quantity-text">Quantity 1</div>
                    <div class="price">$99.00</div>
                  </div>
                </div>
                <div class="cart-product">
                  <div class="inner">
                    <div class="cross-icon"><span class="icon fa fa-remove"></span></div>
                    <div class="image"><img src="images/resource/post-thumb-2.jpg" alt="" /></div>
                    <h3><a href="shop-single.html">Patient Ninja</a></h3>
                    <div class="quantity-text">Quantity 1</div>
                    <div class="price">$99.00</div>
                  </div>
                </div>
                <div class="cart-total">Sub Total: <span>$198</span></div>
                <ul class="btns-boxed">
                  <li><a href="shoping-cart.html">View Cart</a></li>
                  <li><a href="checkout.html">CheckOut</a></li>
                </ul>

              </div>
            </div>
          </div>

          &lt;!&ndash; Search Btn &ndash;&gt;
          <div class="search-box-btn search-box-outer"><span class="icon fa fa-search"></span></div>

          &lt;!&ndash; Nav Btn &ndash;&gt;-->
          <!--<div class="nav-btn navSidebar-button"><span class="icon flaticon-menu-2"></span></div>-->

          <!-- Quote Btn -->
         <!-- <div class="btn-box">
            <a href="contact.html" class="theme-btn btn-style-one"><span class="txt">Free Consulting</span></a>
          </div>-->

       <!-- </div>-->
      <!--</div>-->

    <!--</div>
  </div>-->
  <!--End Header Upper-->

  <!-- Sticky Header  -->
 <!-- <div class="sticky-header">
    <div class="auto-container clearfix">
      &lt;!&ndash;Logo&ndash;&gt;
      <div class="logo pull-left">
        <a href="index.html" title=""><img src="images/logo-small.png" alt="" title=""></a>
      </div>
      &lt;!&ndash;Right Col&ndash;&gt;
      <div class="pull-right">
        &lt;!&ndash; Main Menu &ndash;&gt;
        <nav class="main-menu">
          &lt;!&ndash;Keep This Empty / Menu will come through Javascript&ndash;&gt;
        </nav>&lt;!&ndash; Main Menu End&ndash;&gt;

        &lt;!&ndash; Main Menu End&ndash;&gt;
        <div class="outer-box clearfix">

          &lt;!&ndash; Cart Box &ndash;&gt;
          <div class="cart-box">
            <div class="dropdown">
              <button class="cart-box-btn dropdown-toggle" type="button" id="dropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="flaticon-shopping-bag-1"></span><span class="total-cart">2</span></button>
              <div class="dropdown-menu pull-right cart-panel" aria-labelledby="dropdownMenu">

                <div class="cart-product">
                  <div class="inner">
                    <div class="cross-icon"><span class="icon fa fa-remove"></span></div>
                    <div class="image"><img src="images/resource/post-thumb-1.jpg" alt="" /></div>
                    <h3><a href="shop-single.html">Flying Ninja</a></h3>
                    <div class="quantity-text">Quantity 1</div>
                    <div class="price">$99.00</div>
                  </div>
                </div>
                <div class="cart-product">
                  <div class="inner">
                    <div class="cross-icon"><span class="icon fa fa-remove"></span></div>
                    <div class="image"><img src="images/resource/post-thumb-2.jpg" alt="" /></div>
                    <h3><a href="shop-single.html">Patient Ninja</a></h3>
                    <div class="quantity-text">Quantity 1</div>
                    <div class="price">$99.00</div>
                  </div>
                </div>
                <div class="cart-total">Sub Total: <span>$198</span></div>
                <ul class="btns-boxed">
                  <li><a href="shoping-cart.html">View Cart</a></li>
                  <li><a href="checkout.html">CheckOut</a></li>
                </ul>

              </div>
            </div>
          </div>

          &lt;!&ndash; Search Btn &ndash;&gt;
          <div class="search-box-btn search-box-outer"><span class="icon fa fa-search"></span></div>

          &lt;!&ndash; Nav Btn &ndash;&gt;
          <div class="nav-btn navSidebar-button"><span class="icon flaticon-menu"></span></div>

        </div>

      </div>
    </div>
  </div>--><!-- End Sticky Menu -->

  <!-- Mobile Menu  -->
  <!--<div class="mobile-menu">
    <div class="menu-backdrop"></div>
    <div class="close-btn"><span class="icon flaticon-multiply"></span></div>

    <nav class="menu-box">
      <div class="nav-logo"><a href="index.html"><img src="images/logo.png" alt="" title=""></a></div>
      <div class="menu-outer">&lt;!&ndash;Here Menu Will Come Automatically Via Javascript / Same Menu as in Header&ndash;&gt;</div>
    </nav>
  </div>--><!-- End Mobile Menu -->

</header>
<!-- End Main Header -->


<router-outlet></router-outlet>


  <!-- Main Footer -->
  <footer class="main-footer">
    <div class="pattern-layer-one" style="background-image: url(../assets/images/background/pattern-7.png)"></div>
    <div class="pattern-layer-two" style="background-image: url(../assets/images/background/pattern-8.png)"></div>
    <!--Waves end-->
    <div class="auto-container">
      <!--Widgets Section-->
      <div class="widgets-section">
        <div class="row clearfix">

          <!-- Column -->
          <div class="big-column col-lg-6 col-md-12 col-sm-12">
            <div class="row clearfix">

              <!-- Footer Column -->
              <div class="footer-column col-lg-7 col-md-6 col-sm-12">
                <div class="footer-widget logo-widget">
                  <div class="logo">
                    <a [routerLink]="['/home']"><img src="../assets/images/giventalogo_180x60_menu_bar.png" alt="logo" title=""></a>
                  </div>
                  <div class="text">We are the best world Information Technology Company. Providing the highest quality in hardware & Network solutions. About more than 25 years of experience and 1000 of innovative achievements.</div>
                  <!-- Social Box -->
                  <ul class="social-box">
                    <li><a href="#" class="fa fa-facebook-f"></a></li>
                    <li><a href="#" class="fa fa-linkedin"></a></li>
                    <li><a href="#" class="fa fa-twitter"></a></li>
                    <li><a href="#" class="fa fa-google"></a></li>
                  </ul>
                </div>
              </div>

              <!-- Footer Column -->
              <div class="footer-column col-lg-5 col-md-6 col-sm-12">
                <div class="footer-widget links-widget">
                  <!--<h5>Quick Links</h5>-->
                  <h5>Features</h5>
                  <ul class="list-link">
                    <li class="post" style="color: #ffffff"> <strong>Managed IT Services</strong> </li>
                    <li class="post" style="color: #ffffff"> <strong>Cloud Services</strong> </li>
                    <li class="post" style="color: #ffffff"> <strong>IT Support & Helpdesk</strong> </li>
                    <li class="post" style="color: #ffffff"> <strong>Cyber Security</strong> </li>
                    <li class="post" style="color: #ffffff"> <strong>Custom Software</strong> </li>
                    <li class="post" style="color: #ffffff"> <strong>Free Consultation</strong> </li>
                   <!-- <li>Our Business Growth</li>-->
                  </ul>
                </div>
              </div>

            </div>
          </div>

          <!-- Column -->
          <div class="big-column col-lg-6 col-md-12 col-sm-12">
            <div class="row clearfix">

              <!-- Footer Column -->
             <!-- <div class="footer-column col-lg-6 col-md-6 col-sm-12">
                <div class="footer-widget news-widget">
                  <h5>Resent Post</h5>
                  &lt;!&ndash; Footer Column &ndash;&gt;
                  <div class="widget-content">
                    <div class="post">
                      <div class="thumb"><a href="news-detail.html"><img src="images/resource/post-thumb-3.jpg" alt=""></a></div>
                      <h6><a href="news-detail.html">Define World Best IT Solution Technology</a></h6>
                      <span class="date">May 01, 2020</span>
                    </div>

                    <div class="post">
                      <div class="thumb"><a href="news-detail.html"><img src="images/resource/post-thumb-4.jpg" alt=""></a></div>
                      <h6><a href="news-detail.html">PHP Frameworks You Need To Use Anywhere</a></h6>
                      <span class="date">May 01, 2020</span>
                    </div>
                  </div>
                </div>
              </div>
-->
              <!-- Footer Column -->
              <div class="footer-column col-lg-6 col-md-6 col-sm-12">
                <div class="footer-widget contact-widget">
                  <h5>Contact Us</h5>
                  <ul>
                    <li>
                      <span class="icon flaticon-placeholder-2"></span>
                      <strong>Address</strong>
                      451 W Melrose St, Chicago, IL, USA - 60657
                    </li>
                    <li>
                      <span class="icon flaticon-phone-call"></span>
                      <strong>Phone</strong>
                      <a href="tel:+1 312-343-0073">tel:+1 312-343-0073</a>
                    </li>
                    <li>
                      <span class="icon flaticon-email-1"></span>
                      <strong>E-Mail</strong>
                      <a href="mailto:info@giventa.com">info@giventa.com</a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <!-- Footer Bottom -->
      <div class="footer-bottom">
        <div class="auto-container">
          <div class="row clearfix">
            <!-- Column -->
            <div class="column col-lg-6 col-md-12 col-sm-12">
              <div class="copyright">Copyright &copy; Giventa Inc. All Rights Reserved.</div>
            </div>
            <!-- Column -->
            <div class="column col-lg-6 col-md-12 col-sm-12">
              <ul class="footer-nav">
                <li><a [routerLink]="['/about-us']">About Us</a></li>
                <li><a [routerLink]="['/services']">Services</a></li>
                <li><a href="#">Privacy</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  </footer>  <!-- End Main Footer -->

</div>
<!--End pagewrapper-->
