
<!-- Sidebar Cart Item -->
<div class="xs-sidebar-group info-group">
  <div class="xs-overlay xs-bg-black"></div>
  <div class="xs-sidebar-widget">
    <div class="sidebar-widget-container">
      <div class="widget-heading">
        <a href="#" class="close-side-widget">
          X
        </a>
      </div>
      <div class="sidebar-textwidget">

        <!-- Sidebar Info Content -->
        <div class="sidebar-info-contents">
          <div class="content-inner">
            <div class="logo">
            <!--  <a href="index.html"><img src="images/logo-2.png" alt="" /></a>-->
            </div>
            <div class="content-box">
              <h2>About Us</h2>
              <p class="text">The argument in favor of using filler text goes something like this: If you use real content in the Consulting Process, anytime you reach a review point you’ll end up reviewing and negotiating the content itself and not the design.</p>
              <a href="#" class="theme-btn btn-style-two"><span class="txt">Consultation</span></a>
            </div>
            <div class="contact-info">
              <h2>Contact Info</h2>
              <ul class="list-style-one">
                <li><span class="icon fa fa-location-arrow"></span> 451 W Melrose St, Chicago, IL, USA - 60657 </li>
                <li><span class="icon fa fa-phone"></span> +1 312-343-0073 </li>
                <li><span class="icon fa fa-envelope"></span>info@giventa.com</li>
                <li><span class="icon fa fa-clock-o"></span>Week Days: 09.00 to 18.00 Sunday: Closed</li>
              </ul>
            </div>
            <!-- Social Box -->
            <ul class="social-box">
              <li class="facebook"><a href="#" class="fa fa-facebook-f"></a></li>
              <li class="twitter"><a href="#" class="fa fa-twitter"></a></li>
              <li class="linkedin"><a href="#" class="fa fa-linkedin"></a></li>
              <li class="instagram"><a href="#" class="fa fa-instagram"></a></li>
              <li class="youtube"><a href="#" class="fa fa-youtube"></a></li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- END sidebar widget item -->

<!--Page Title-->
<section class="page-title">
  <div class="pattern-layer-one" style="background-image: url(../../assets/images/background/pattern-16.png)"></div>
  <div class="auto-container">
    <h2>Contact us</h2>
    <ul class="page-breadcrumb">
      <li><a href="index.html">home</a></li>
      <li>Contact us</li>
    </ul>
  </div>
</section>
<!--End Page Title-->

<!-- Contact Info Section -->
<section class="contact-info-section">
  <div class="auto-container">
    <!-- Sec Title -->
    <div class="title-box">
      <div class="title">GET IN TOUCH</div>
      <h2>A Monthly Project Fee <br> Price Plans</h2>
      <div class="text">For general enquires you can touch with our front desk supporting team <br> at <a href="mailto:info@example.com">info@giventa.com</a> or call on <a href="tel:+1 312-343-0073"> +1 312-343-0073 </a></div>
    </div>

    <div class="row clearfix">

      <!-- Info Column -->
      <div class="info-column col-lg-4 col-md-6 col-sm-12">
        <div class="inner-column">
          <div class="content">
            <div class="icon-box"><span class="flaticon-pin"></span></div>
            <ul>
              <li><strong>Address</strong></li>
              <li> 451 W Melrose St, Chicago, IL, USA - 60657 </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Info Column -->
      <div class="info-column col-lg-4 col-md-6 col-sm-12">
        <div class="inner-column">
          <div class="content">
            <div class="icon-box"><span class="flaticon-phone-call"></span></div>
            <ul>
              <li><strong>Phone</strong></li>
              <li> +1 312-343-0073 </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Info Column -->
      <div class="info-column col-lg-4 col-md-6 col-sm-12">
        <div class="inner-column">
          <div class="content">
            <div class="icon-box"><span class="flaticon-email-1"></span></div>
            <ul>
              <li><strong>E-Mail</strong></li>
              <li> info@giventa.com </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="lower-text">Our resources are at different locations over the country and the globe</div>
  </div>
</section>
<!-- End Contact Info Section -->

<!-- Map Section -->
<section class="contact-map-section">
  <div class="auto-container">
    <!-- Map Boxed -->
    <div class="map-boxed">
      <!--Map Outer-->
      <div class="map-outer">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d380513.7159859942!2d-88.01214778988322!3d41.83339250495681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2c3cd0f4cbed%3A0xafe0a6ad09c0c000!2sChicago%2C%20IL!5e0!3m2!1sen!2sus!4v1628134971181!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" ></iframe>
      </div>
    </div>
  </div>
</section>
<!-- End Map Section -->

<!-- Contact Map Section -->
<!--<section class="contact-map-section">
  <div class="auto-container">
    &lt;!&ndash; Sec Title &ndash;&gt;
    <div class="sec-title">
      <div class="clearfix">
        <div class="pull-left">
          <div class="title">SEND YOUR MESSAGE</div>
          <h2>Send Your Message</h2>
        </div>
        <div class="pull-right">
          <div class="text">Our goal is to help our companies maintain or achieve best- in-class <br> positions in their respective industries and our team works.</div>
        </div>
      </div>
    </div>

    &lt;!&ndash; Contact Form &ndash;&gt;
    <div class="contact-form">

      &lt;!&ndash; Contact Form &ndash;&gt;
      <form method="post" action="sendemail.php" id="contact-form">
        <div class="row clearfix">

          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label>Your name *</label>
            <input type="text" name="username" placeholder="" required>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label>Email address *</label>
            <input type="text" name="email" placeholder="" required>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label>Phone number *</label>
            <input type="text" name="phone" placeholder="" required>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label>Website</label>
            <input type="text" name="subject" placeholder="" required>
          </div>

          <div class="form-group col-lg-12 col-md-12 col-sm-12">
            <label>Your Message *</label>
            <textarea name="message" placeholder=""></textarea>
          </div>

          <div class="form-group text-center col-lg-12 col-md-12 col-sm-12">
            <button class="theme-btn btn-style-three" type="submit" name="submit-form"><span class="txt">Send Now</span></button>
          </div>

        </div>
      </form>
    </div>
    &lt;!&ndash; End Contact Form &ndash;&gt;

  </div>
</section>-->
<!-- End Contact Map Section -->
