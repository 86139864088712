<!-- Sidebar Cart Item -->
<div class="xs-sidebar-group info-group">
  <div class="xs-overlay xs-bg-black"></div>
  <div class="xs-sidebar-widget">
    <div class="sidebar-widget-container">
      <div class="widget-heading">
        <a [routerLink]="['/services']" class="close-side-widget">
          X
        </a>
      </div>
      <div class="sidebar-textwidget">

        <!-- Sidebar Info Content -->
        <div class="sidebar-info-contents">
          <div class="content-inner">
            <div class="logo">
             <!-- <a href="index.html"><img src="images/logo-2.png" alt="" /></a>-->
            </div>
            <div class="content-box">
              <h2>About Us</h2>
              <p class="text">The argument in favor of using filler text goes something like this: If you use real content in the Consulting Process, anytime you reach a review point you’ll end up reviewing and negotiating the content itself and not the design.</p>
              <a [routerLink]="['/services']" class="theme-btn btn-style-two"><span class="txt">Consultation</span></a>
            </div>
            <div class="contact-info">
              <h2>Contact Info</h2>
              <ul class="list-style-one">
                <li><span class="icon fa fa-location-arrow"></span>Chicago 12, Melborne City, USA</li>
                <li><span class="icon fa fa-phone"></span>(111) 111-111-1111</li>
                <li><span class="icon fa fa-envelope"></span>globex@gmail.com</li>
                <li><span class="icon fa fa-clock-o"></span>Week Days: 09.00 to 18.00 Sunday: Closed</li>
              </ul>
            </div>
            <!-- Social Box -->
            <ul class="social-box">
              <li class="facebook"><a [routerLink]="['/services']" class="fa fa-facebook-f"></a></li>
              <li class="twitter"><a [routerLink]="['/services']" class="fa fa-twitter"></a></li>
              <li class="linkedin"><a [routerLink]="['/services']" class="fa fa-linkedin"></a></li>
              <li class="instagram"><a [routerLink]="['/services']" class="fa fa-instagram"></a></li>
              <li class="youtube"><a [routerLink]="['/services']" class="fa fa-youtube"></a></li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- END sidebar widget item -->

<!--Page Title-->
<section class="page-title">
  <div class="pattern-layer-one" style="background-image: url(../../assets/images/background/pattern-16.png)"></div>
  <div class="auto-container">
    <h2>Services</h2>
    <ul class="page-breadcrumb">
      <li><a href="index.html">home</a></li>
      <li>Services</li>
    </ul>
  </div>
</section>
<!--End Page Title-->

<!-- Services Page Section -->
<section class="services-page-section">
  <div class="auto-container">
    <div class="row clearfix">

      <!-- News Block Three -->
      <div class="news-block-three col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a [routerLink]="['/services']"><img src="../../assets/images/resource/news-5.jpg" alt="" /></a>
          </div>
          <div class="lower-content">
            <div class="content">
              <div class="icon-box">
                <span class="icon flaticon-coding-1"></span>
              </div>
              <h4><a [routerLink]="['/services']">Web Development</a></h4>
              <div class="text"> We carry more than just good coding skills. Our experience makes us stand out from other web development. </div>
             <!-- <a class="read-more" [routerLink]="['/services']">Read More<span class="arrow flaticon-long-arrow-pointing-to-the-right"></span></a>-->
            </div>
          </div>
        </div>
      </div>

      <!-- News Block Three -->
      <div class="news-block-three style-two col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="lower-content">
            <div class="content">
              <div class="icon-box">
                <span class="icon flaticon-mobile-app"></span>
              </div>
              <h4><a [routerLink]="['/services']">Mobile Development</a></h4>
              <div class="text">  Android, iOS, and cross-platform app development. Use the expertise of full-stack developers from a reliable provider. </div>
              <!--<a class="read-more" [routerLink]="['/services']">Read More<span class="arrow flaticon-long-arrow-pointing-to-the-right"></span></a>-->
            </div>
          </div>
          <div class="image">
            <a [routerLink]="['/services']"><img src="../../assets/images/resource/news-6.jpg" alt="" /></a>
          </div>
        </div>
      </div>

      <!-- News Block Three -->
      <div class="news-block-three col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a [routerLink]="['/services']"><img src="../../assets/images/resource/news-7.jpg" alt="" /></a>
          </div>
          <div class="lower-content">
            <div class="content">
              <div class="icon-box">
                <span class="icon flaticon-computer"></span>
              </div>
              <h4><a [routerLink]="['/services']">UI/UX Design</a></h4>
              <div class="text">Build the product you need on time with an experienced team that uses a clear and effective design process.</div>
              <!--<a class="read-more" [routerLink]="['/services']">Read More<span class="arrow flaticon-long-arrow-pointing-to-the-right"></span></a>-->
            </div>
          </div>
        </div>
      </div>

      <!-- News Block Three -->
      <div class="news-block-three col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a [routerLink]="['/services']"><img src="../../assets/images/resource/news-11.jpg" alt="" /></a>
          </div>
          <div class="lower-content">
            <div class="content">
              <div class="icon-box">
                <span class="icon flaticon-web"></span>
              </div>
              <h4><a [routerLink]="['/services']">QA & Testing</a></h4>
              <div class="text">Turn to our experts to perform comprehensive, multi-stage testing and auditing of your software.</div>
             <!-- <a class="read-more" [routerLink]="['/services']">Read More<span class="arrow flaticon-long-arrow-pointing-to-the-right"></span></a>-->
            </div>
          </div>
        </div>
      </div>

      <!-- News Block Three -->
      <div class="news-block-three col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a [routerLink]="['/services']"><img src="../../assets/images/resource/news-12.jpg" alt="" /></a>
          </div>
          <div class="lower-content">
            <div class="content">
              <div class="icon-box">
                <span class="icon flaticon-monitor-2"></span>
              </div>
              <h4><a [routerLink]="['/services']">IT Consultancy</a></h4>
              <div class="text"> Tailor-made custom software development for your business needs. We help you build THE technological solution for you. IT Outsourcing. </div>
              <!--<a class="read-more" [routerLink]="['/services']">Read More<span class="arrow flaticon-long-arrow-pointing-to-the-right"></span></a>-->
            </div>
          </div>
        </div>
      </div>

      <!-- News Block Three -->
      <div class="news-block-three col-lg-4 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="image">
            <a [routerLink]="['/services']"><img src="../../assets/images/resource/news-13.jpg" alt="" /></a>
          </div>
          <div class="lower-content">
            <div class="content">
              <div class="icon-box">
                <span class="icon flaticon-human-resources"></span>
              </div>
              <h4><a [routerLink]="['/services']">Dedicated Team</a></h4>
              <div class="text">Over the past decade, our customers succeeded by leveraging Giventa’s process of building, motivating.</div>
              <!--<a class="read-more" [routerLink]="['/services']">Read More<span class="arrow flaticon-long-arrow-pointing-to-the-right"></span></a>-->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
<!-- End Services Page Section -->

<!-- Featured Section -->
<section class="featured-section">
  <div class="auto-container">
    <div class="row clearfix">

      <!-- Featured Block Two -->
      <div class="feature-block-two col-lg-6 col-md-12 col-sm-12">
        <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms" style="background-image: url(../../assets/images/resource/feature-1.jpg)">
          <div class="number">35 +</div>
          <h4>Worldwide Work Pair</h4>
          <div class="text">To succeed, every software solution must be deeply integrated into the existing tech environment..</div>
        </div>
      </div>

      <!-- Featured Block Two -->
      <div class="feature-block-two col-lg-6 col-md-12 col-sm-12">
        <div class="inner-box wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms" style="background-image: url(../../assets/images/resource/feature-2.jpg)">
          <div class="number">100 +</div>
          <h4>Happy Clients</h4>
          <div class="text">To succeed, every software solution must be deeply integrated into the existing tech environment..</div>
        </div>
      </div>

    </div>
  </div>
</section>
<!-- End Featured Section -->

<!-- Services Section -->
<section class="services-section margin-top">
  <div class="pattern-layer" style="background-image: url(../../assets/images/background/pattern-2.png)"></div>
  <div class="auto-container">
    <!-- Sec Title -->
    <div class="sec-title light centered">
      <div class="title">WHO WE ARE</div>
      <h2>We deal with the aspects of <br> professional IT Services</h2>
    </div>
    <div class="row clearfix">

      <!-- Service Block -->
      <div class="service-block col-lg-3 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="icon-box">
            <span class="icon flaticon-responsive"></span>
          </div>
          <h5><a [routerLink]="['/services']">IT Solutions</a></h5>
          <!--<div class="text">Sed ut perspiciatis unde omnis iste natus error volup validate your ideas.</div>
          <a [routerLink]="['/services']" class="arrow flaticon-long-arrow-pointing-to-the-right"></a>-->
        </div>
      </div>

      <!-- Service Block -->
      <div class="service-block col-lg-3 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="icon-box">
            <span class="icon flaticon-monitor"></span>
          </div>
          <h5><a [routerLink]="['/services']">Security System</a></h5>
          <!--<div class="text">Sed ut perspiciatis unde omnis iste natus error volup validate your ideas.</div>
          <a [routerLink]="['/services']" class="arrow flaticon-long-arrow-pointing-to-the-right"></a>-->
        </div>
      </div>

      <!-- Service Block -->
      <div class="service-block col-lg-3 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="icon-box">
            <span class="icon flaticon-coding"></span>
          </div>
          <h5><a [routerLink]="['/services']">Web Development</a></h5>
          <!--<div class="text">Sed ut perspiciatis unde omnis iste natus error volup validate your ideas.</div>
          <a [routerLink]="['/services']" class="arrow flaticon-long-arrow-pointing-to-the-right"></a>-->
        </div>
      </div>

      <!-- Service Block -->
      <div class="service-block col-lg-3 col-md-6 col-sm-12">
        <div class="inner-box">
          <div class="icon-box">
            <span class="icon flaticon-laptop"></span>
          </div>
          <h5><a [routerLink]="['/services']">Database Security</a></h5>
          <!--<div class="text">Sed ut perspiciatis unde omnis iste natus error volup validate your ideas.</div>
          <a [routerLink]="['/services']" class="arrow flaticon-long-arrow-pointing-to-the-right"></a>-->
        </div>
      </div>

    </div>
  </div>
</section>
<!-- End Services Section -->

<!-- Services Section Two -->
<section class="services-section-two margin-top">
  <div class="auto-container">
    <div class="upper-box">
      <div class="icon-one" style="background-image: url(../../assets/images/icons/icon-1.png)"></div>
      <div class="icon-two" style="background-image: url(../../assets/images/icons/icon-2.png)"></div>
      <div class="icon-three" style="background-image: url(../../assets/images/icons/icon-3.png)"></div>
      <!-- Sec Title -->
      <div class="sec-title light centered">
        <div class="title">WHO WE ARE</div>
        <h2>We deal with the aspects of <br> professional IT Services</h2>
      </div>
    </div>
    <div class="inner-container">
      <div class="row clearfix">

        <!-- Service Block Two -->
        <div class="service-block-two col-lg-4 col-md-6 col-sm-12">
          <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
            <div class="shape-one"></div>
            <div class="shape-two"></div>
            <div class="icon-box">
              <span class="icon flaticon-coding-1"></span>
            </div>
            <h5><a [routerLink]="['/services']">Web Develpment</a></h5>
            <div class="text">We carry more than just good coding skills. Our experience makes us stand out from other web development.</div>
          </div>
        </div>

        <!-- Service Block Two -->
        <div class="service-block-two col-lg-4 col-md-6 col-sm-12">
          <div class="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
            <div class="shape-one"></div>
            <div class="shape-two"></div>
            <div class="icon-box">
              <span class="icon flaticon-mobile-app"></span>
            </div>
            <h5><a [routerLink]="['/services']">Mobile Development</a></h5>
            <div class="text">Create complex enterprise software, ensure reliable software integration, modernise your legacy system.</div>
          </div>
        </div>

        <!-- Service Block Two -->
        <div class="service-block-two col-lg-4 col-md-6 col-sm-12">
          <div class="inner-box wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
            <div class="shape-one"></div>
            <div class="shape-two"></div>
            <div class="icon-box">
              <span class="icon flaticon-computer"></span>
            </div>
            <h5><a [routerLink]="['/services']">UI/UX Design</a></h5>
            <div class="text">Build the product you need on time with an experienced team that uses a clear and effective design process.</div>
          </div>
        </div>

        <!-- Service Block Two -->
        <div class="service-block-two col-lg-4 col-md-6 col-sm-12">
          <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
            <div class="shape-one"></div>
            <div class="shape-two"></div>
            <div class="icon-box">
              <span class="icon flaticon-web"></span>
            </div>
            <h5><a [routerLink]="['/services']">QA & Testing</a></h5>
            <div class="text">Turn to our experts to perform comprehensive, multi-stage testing and auditing of your software.</div>
          </div>
        </div>

        <!-- Service Block Two -->
        <div class="service-block-two col-lg-4 col-md-6 col-sm-12">
          <div class="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
            <div class="shape-one"></div>
            <div class="shape-two"></div>
            <div class="icon-box">
              <span class="icon flaticon-monitor-2"></span>
            </div>
            <h5><a [routerLink]="['/services']">IT Counsultancy</a></h5>
            <div class="text">Trust our top minds to eliminate workflow pain points, implement new tech, and consolidate app portfolios.</div>
          </div>
        </div>

        <!-- Service Block Two -->
        <div class="service-block-two col-lg-4 col-md-6 col-sm-12">
          <div class="inner-box wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
            <div class="shape-one"></div>
            <div class="shape-two"></div>
            <div class="icon-box">
              <span class="icon flaticon-human-resources"></span>
            </div>
            <h5><a [routerLink]="['/services']">Dedicated Team</a></h5>
            <div class="text">Over the past decade, our customers succeeded by leveraging Intellectsoft’s process of building, motivating.</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
<!-- End Services Section Two -->

<!-- Appointment Form Section -->
<!--<section class="appointment-section style-two">
  <div class="image-layer" style="background-image: url(../../assets/images/background/4.jpg)"></div>
  <div class="auto-container">
    &lt;!&ndash; Sec Title &ndash;&gt;
    <div class="sec-title light centered">
      <div class="title">CONTACT US</div>
      <h2>Join Us To Get IT Free <br> Consultations</h2>
    </div>
    <div class="inner-container">
      <div class="row clearfix">

        &lt;!&ndash; Image Column &ndash;&gt;
        <div class="image-column col-lg-6 col-md-12 col-sm-12">
          <div class="inner-column wow slideInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
            <div class="image">
              <img src="../../assets/images/resource/appointment.jpg" alt="" />
            </div>
          </div>
        </div>

        &lt;!&ndash; Form Column &ndash;&gt;
        <div class="form-column col-lg-6 col-md-12 col-sm-12">
          <div class="inner-column">
            <h4>You Don't Hesitate To Contact <br> With Us, Now Say Hello......</h4>
            &lt;!&ndash; Appointment Form &ndash;&gt;
            <div class="appointment-form">
              <form method="post" action="appointment.html">
                <div class="row clearfix">
                  <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                    <input type="text" name="username" placeholder="Name" required="">
                    <span class="icon fa fa-user"></span>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                    <input type="email" name="email" placeholder="Email" required="">
                    <span class="icon fa fa-envelope"></span>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                    <input type="tel" name="phone" placeholder="Phone No" required="">
                    <span class="icon fa fa-phone"></span>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                    <input type="text" name="department" placeholder="Department" required="">
                    <span class="icon fa fa-home"></span>
                  </div>

                  <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                    <textarea name="message" placeholder="Message"></textarea>
                  </div>

                  <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                    <button class="theme-btn btn-style-three" type="submit" name="submit-form"><span class="txt">Send Massage</span></button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</section>-->

<!-- Info Section -->
<!--<section class="info-section" style="background-image: url(../../assets/images/background/6.jpg)">
  <div class="auto-container">
    <div class="row clearfix">

      &lt;!&ndash; Logo Column &ndash;&gt;
      <div class="logo-column col-lg-3 col-md-6 col-sm-12">
        <div class="inner-column">
          <div class="logo">
            <a href="index.html"><img src="images/logo-2.png" alt="" /></a>
          </div>
        </div>
      </div>

      &lt;!&ndash; Info Column &ndash;&gt;
      <div class="info-column col-lg-3 col-md-6 col-sm-12">
        <div class="inner-column">
          <div class="icon-box"><span class="flaticon-pin"></span></div>
          <ul>
            <li><strong>Address</strong></li>
            <li>125, Suitland Street, USA</li>
          </ul>
        </div>
      </div>

      &lt;!&ndash; Info Column &ndash;&gt;
      <div class="info-column col-lg-3 col-md-6 col-sm-12">
        <div class="inner-column">
          <div class="icon-box"><span class="flaticon-phone-call"></span></div>
          <ul>
            <li><strong>Phone</strong></li>
            <li>+ 786 875 864 75</li>
          </ul>
        </div>
      </div>

      &lt;!&ndash; Info Column &ndash;&gt;
      <div class="info-column col-lg-3 col-md-6 col-sm-12">
        <div class="inner-column">
          <div class="icon-box"><span class="flaticon-email-1"></span></div>
          <ul>
            <li><strong>E-Mail</strong></li>
            <li>support@globex.com</li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</section>-->
<!-- End Info Section -->
